import * as React from "react"

import {Link} from "gatsby";
import LayoutComponent from "../components/layout/LayoutComponent"
import SeoComponent from "../components/seo/SeoComponent"
import InputMask from "react-input-mask";
import { ContainerComponent } from "../components/container/ContainerComponent"

import logo from '../images/logo.svg';
import phone from '../images/phone.svg';
import insta from '../images/insta.svg';
import mail from '../images/mail.svg';

import * as styles from './styles/main-page.module.css'
import {useState} from "react";

const IndexPage = () => {
    const [value, setValue] = useState('');

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const submit = (e) => {
        e.preventDefault();

        if (value.replaceAll('_', '').length === 16) {
            const data = new FormData();
            data.append('user_phone', value);
            let xhr = new XMLHttpRequest();
            xhr.open("POST", 'mail.php', true);
            xhr.send(data);

            xhr.addEventListener("load", (e) => {
                setValue('');
                alert('Форма успешно отправлена')
            }, false);
        }
    }

    return (
        <LayoutComponent>
            <SeoComponent title="Главная" />
            <ContainerComponent>
                <section className={styles.wrapper}>
                    <img className={styles.img} src={logo} alt="logo"/>
                    <h1 className={styles.title}>Сайт находится в разработке</h1>
                    <p className={styles.subTitle}>Если у вас есть вопросы или вы хотите заказать химчистку,  оставьте свой номер и мы с вами свяжемся в течение 1 минуты</p>
                    <form className={styles.form} onSubmit={submit}>
                        <InputMask
                            name="user_phone"
                            className={styles.input}
                            mask="+7(999)999-99-99"
                            placeholder="+7(999)999-99-99"
                            onChange={onChange}
                            value={value}
                        />
                        <button type="submit" className={styles.button}>Заказать звонок</button>
                        <p className={styles.text}>Нажимая на кнопку «Заказать звонок», я даю согласие на <Link className={styles.link} to="/policy">Обработку персональных данных</Link> .</p>
                    </form>
                    <ul className={styles.social}>
                        <li className={styles.socialItem}>
                            <span className={styles.cube}>
                                <img className={styles.icon} src={phone} alt="+7 (495) 589-00-09"/>
                            </span>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column'
                            }}>
                                <a className={styles.socialLink} href="tel:+7(495)5890009" target="_blank" rel="noreferrer">+7 (495) 589-00-09</a>
                                <a className={styles.socialLink} href="tel:+7(926)6327055" target="_blank" rel="noreferrer">+7 (926) 632-70-55</a>
                            </div>
                        </li>

                        <li className={styles.socialItem}>
                            <span className={styles.cube}>
                                <img className={styles.icon} src={insta} alt="cleantruck.pro_official"/>
                            </span>
                            <a className={styles.socialLink} href="https://instagram.com/cleantruck.pro_official" target="_blank" rel="noreferrer">cleantruck.pro_official</a>
                        </li>

                        <li className={styles.socialItem}>
                            <span className={styles.cube}>
                                <img className={styles.icon} src={mail} alt="cleantruck.pro@gmail.com"/>
                            </span>
                            <a className={styles.socialLink} href="mailto:cleantruck.pro@gmail.com" target="_blank" rel="noreferrer">cleantruck.pro@gmail.com</a>
                        </li>
                    </ul>
                </section>
            </ContainerComponent>
        </LayoutComponent>
    )
}

export default IndexPage
