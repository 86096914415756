// extracted by mini-css-extract-plugin
export var wrapper = "main-page-module--wrapper--1I8Sl";
export var img = "main-page-module--img--30JUs";
export var title = "main-page-module--title--3evl5";
export var subTitle = "main-page-module--subTitle--i9HS7";
export var input = "main-page-module--input--2Q0Rr";
export var button = "main-page-module--button--vWKjF";
export var text = "main-page-module--text--3vQpZ";
export var form = "main-page-module--form--HBJip";
export var link = "main-page-module--link--2n_Vg";
export var social = "main-page-module--social--2YoU8";
export var socialItem = "main-page-module--socialItem--Qo7vX";
export var cube = "main-page-module--cube--3rZAD";
export var icon = "main-page-module--icon--17EDz";
export var socialLink = "main-page-module--socialLink--1MmVF";